import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { SectionProps } from "../../utils/SectionProps";
import Image from "../elements/Image";
import Spinner from "../elements/Spinner";
// import Modal from "../elements/Modal";

import { ThetaDropConnect } from "@thetalabs/theta-drop-connect";

//dev
// const AppId = "dapp_8gsf5446h44rsrpyun0pu5qqztm";
// const redirectURL = "http://localhost:3000/oott/login";

//prod
const AppId =
  "dapp_mqb1p6hqchfkkeq8m5c7a7vkg8u" || process.env.REACT_APP_APP_ID;
const redirectURL = process.env.REACT_APP_REDIRECT_URL;

const TigonMarketplaceUrl = "https://tigons.thetadrop.com/";
const TigonId = "type_tpqv6d16v5fp0gudtt0vyf6qsqm";

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
};

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
};

class LoginSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false, showModal: false };
    this.thetaDrop = new ThetaDropConnect();
  }

  componentWillMount() {
    const tpsId = localStorage.getItem("tpsId");
    const authToken = localStorage.getItem("authToken");
    this.props.setUser({
      tpsId: tpsId,
      authToken: authToken,
    });
    // DEV URL
    // let newURL = `http://localhost:3000/oott/login/?sns_id=${tpsId}&oauth2_token=${authToken}`;

    // PROD URL
    let newURL = `https://www.orderofthetigons.com/oott/login/?sns_id=${tpsId}&oauth2_token=${authToken}`;

    if (
      window.location.href === "http://localhost:3000/oott/login" ||
      window.location.href === "https://www.orderofthetigons.com/oott/login/"
    ) {
      window.location.replace(newURL);
    }
  }

  componentDidMount() {
    // force reset state - acts as logout
    if (
      this.props.state.userData &&
      !this.props.state.isOwner &&
      this.props.state.isLoadingComplete
    ) {
      this.props.setUser({
        isLoginComplete: false,
        userData: undefined,
      });
    }
    // Optional: Use only if using the redirect option
    this.finishConnectViaRedirect();
  }

  finishConnectViaRedirect = async () => {
    const result = await this.thetaDrop.finishConnectViaRedirect();

    if (result) {
      const { snsId, oauth2Token } = result;

      this.props.setUser({
        tpsId: snsId,
        authToken: oauth2Token,
      });

      localStorage.setItem("tpsId", snsId);
      localStorage.setItem("authToken", oauth2Token);

      this.refreshUser();
      this.refreshOwnershipChecks();
    }

    this.setState({ ...this.state, isLoading: false });
  };

  refreshOwnershipChecks = async () => {
    const filters = {
      content_id: TigonId,
    };
    const nfts = await this.thetaDrop.fetchUserNFTs(filters);

    const isOwner = await this.thetaDrop.checkUserIsOwner(filters);

    this.props.setUser({
      isOwner: isOwner,
      nfts: nfts,
      isLoginComplete: true,
    });
  };

  refreshUser = async () => {
    const userData = await this.thetaDrop.fetchUser();

    this.props.setUser({
      userData: userData,
    });
  };

  connectToThetaDrop = async () => {
    const { snsId, oauth2Token } = await this.thetaDrop.connectViaPopup(
      AppId,
      redirectURL
    );

    this.props.setUser({
      tpsId: snsId,
      authToken: oauth2Token,
    });

    this.refreshUser();
    this.refreshOwnershipChecks();
  };

  connectToThetaDropViaRedirect = async () => {
    this.state = { isLoading: true };
    this.thetaDrop.connectViaRedirect(AppId, redirectURL);
  };

  accessDeniedMessage = () => {
    const messages = [
      "I think you might have lost your Tigon!",
      "Kitty is probably just out exploring the Metaverse",
      "ROAR!",
      "Where oh where has my Tigon gone?",
      "Head on over to ThetaDrop and adopt a Tigon",
      "Lost Tigon, Answers to Fluf, HUGE REWARD",
    ];
    let randomMessage =
      messages[Math.floor(Math.random() * messages.length - 1)];
    return randomMessage;
  };

  render() {
    const { userData, isOwner, isLoginComplete } = this.props.state;
    const { isLoading } = this.state;

    if (isOwner) {
      this.props.history.push("/oott/TigonSanctuary");
    }
    return (
      <div className="login-screen">
        <div className="login-screen-header">
          <Link to={"/"}>
            <Image
              src={
                "https://static.wixstatic.com/media/b85c66_158137e8f9104108b46e18cb7d540071~mv2.png"
              }
              alt="logo-img"
            />
          </Link>
          <Image
            src={
              "https://static.wixstatic.com/media/b85c66_1acd3ae0a20e4158ba0d7dedd389f5bf~mv2.png"
            }
            alt="Welcome-to-sanctuary-img"
          />
          <p id="members-text">
            A members only community of like-minded individuals.
            <br />
            The Sanctuary is the place for Tigons owners to communicate, access
            the Tigon Merch Store, and much more to come.
          </p>
        </div>

        {userData === undefined && (
          <>
            <div className="empty-div"></div>
            <div className="btns-container">
              <div className="theta-connect-btn">
                <button onClick={this.connectToThetaDropViaRedirect}>
                  <Spinner spin={isLoading}>
                    Connect
                    <Image
                      src={
                        "https://static.wixstatic.com/shapes/b85c66_d1545a018d0d4e69856ff00ac8a1b7a5.svg"
                      }
                      alt="tdlogo-img"
                      className="td-logo-svg"
                    />
                  </Spinner>
                </button>
                <p className="sub-text">
                  Connecting to ThetaDrop grants us access to view your Tigons
                  and Username ONLY.
                  <br />
                  We CANNOT access your personal information or balances.
                </p>

                <p className="sub-text">
                  Your Tigon must be released from their Cat Box and safely at
                  Home in your{" "}
                  <a
                    href={`${TigonMarketplaceUrl}account/collection`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ThetaDrop Inventory
                  </a>{" "}
                  right next to your tropical fruits, spirits, cool artwork,
                  poker buddies, and other unusual pets.
                </p>
              </div>
              <button>
                <a
                  href={TigonMarketplaceUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Marketplace
                </a>
              </button>
            </div>
          </>
        )}

        {/* Access Denied
         */}
        {userData !== undefined && !isOwner && isLoginComplete && (
          <>
            <div className="error-div">
              <h4 className="errorMsg">{this.accessDeniedMessage()}</h4>
            </div>
            <div className="btns-container">
              <div className="theta-connect-btn">
                <button onClick={this.connectToThetaDropViaRedirect}>
                  <Spinner spin={isLoading}>
                    Connect
                    <Image
                      src={
                        "https://static.wixstatic.com/shapes/b85c66_d1545a018d0d4e69856ff00ac8a1b7a5.svg"
                      }
                      alt="tdlogo-img"
                      className="td-logo-svg"
                    />
                  </Spinner>
                </button>
                <p className="sub-text">
                  Connecting to ThetaDrop grants us access to view your Tigons
                  and Username ONLY.
                  <br />
                  We CANNOT access your personal information or balances.
                </p>

                <p className="sub-text">
                  Your Tigon must be released from their Cat Box and safely at
                  Home in your{" "}
                  <a
                    href={`${TigonMarketplaceUrl}account/collection`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ThetaDrop Inventory
                  </a>{" "}
                  right next to your tropical fruits, spirits, cool artwork,
                  poker buddies, and other unusual pets.
                </p>
              </div>
              <button>
                <a
                  href={TigonMarketplaceUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Marketplace
                </a>
              </button>
            </div>
          </>
        )}
        <footer>
          <Image
            src={
              "https://static.wixstatic.com/media/b85c66_42bb3636e7374595a9b7b5dd69409b48~mv2.png"
            }
            alt="logo-img"
          />
        </footer>
      </div>
    );
  }
}

LoginSection.propTypes = propTypes;
LoginSection.defaultProps = defaultProps;

export default LoginSection;
