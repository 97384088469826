import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Input from "../elements/Input";
import Image from "../elements/Image";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const MCCustomForm = ({ status, message, onSubmitted }) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    email &&
      email.indexOf("@") > -1 &&
      onSubmitted({
        EMAIL: email,
      });
  };

  useEffect(() => {
    if (status === "success") clearFields();
  }, [status]);

  const clearFields = () => {
    setEmail("");
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <form onSubmit={(event) => handleSubmit(event)}>
      <input
        id="newsletter"
        name="email"
        value={email}
        type="email"
        label="Email"
        placeholder="  tigon@example.com"
        onChange={handleChange}
      />

      <button label="subscribe" type="submit">
        Subscribe
      </button>

      {status === "error" && (
        <div
          className="mc__alert mc__alert--error"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}

      {status === "success" && (
        <div
          className="mc__alert mc__alert--success"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    </form>
  );
};

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const postUrl = `https://gmail.us14.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MC_U}&id=${process.env.REACT_APP_MC_ID}`;

  const outerClasses = classNames(
    "cta section center-content-mobile reveal-from-top",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "cta-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-lrg">
        <div className={innerClasses}>
          <div className="cta-slogan">
            <div className="container-xs">
              <Image
                src={
                  "https://static.wixstatic.com/media/b85c66_aa024f2e7de44b25a56384753e503548~mv2.png"
                }
                alt={"JoinOOTTtext"}
              />
            </div>
          </div>
          <div className="cta-action">
            <MailchimpSubscribe
              url={postUrl}
              render={({ subscribe, status, message }) => (
                <MCCustomForm
                  status={status}
                  message={message}
                  onSubmitted={(formData) => subscribe(formData)}
                />
              )}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
