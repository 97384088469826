import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Image from "../elements/Image";

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
};

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
};

const RoadmapSection = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className,
    "section-how-to-buy"
  );

  const innerClasses = classNames(
    "section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="how-to-buy container-large">
            <Image
              src={
                "https://static.wixstatic.com/media/b85c66_7a4973e5cb4e4aa387976061ead95cb3~mv2.png"
              }
              alt="how-to-buy-text"
              className={"reveal-from-top"}
            />
            <a
              target={"_blank"}
              href={"https://tigons.thetadrop.com/"}
              rel="noopener noreferrer"
            >
              <Image
                src={
                  "https://static.wixstatic.com/media/b85c66_6afc665a6f5242beac223e47ae203e38~mv2.png"
                }
                alt="theta-drop-logo-link"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

RoadmapSection.propTypes = propTypes;
RoadmapSection.defaultProps = defaultProps;

export default RoadmapSection;
