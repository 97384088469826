import React from "react";
import { Route, Redirect } from "react-router-dom";
// import auth from "./auth";

export const PrivateRoute = ({
  component: Component,
  layout: Layout,
  state,
  ...rest
}) => {
  Layout = Layout === undefined ? (props) => <>{props.children}</> : Layout;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (state.isOwner) {
          return <Component {...props} state={state} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/oott/login",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};
