import React, { useRef, useEffect, useState } from "react";
import { Route, useLocation, Switch, Redirect } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import { PrivateRoute } from "./utils/PrivateRoute.js";
import ScrollReveal from "./utils/ScrollReveal";
import ReactGA from "react-ga";

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";

// Views
import Home from "./views/Home";
import LoginScreen from "./components/sections/LoginScreen";
import Shop from "./components/sections/Shop";
import Lore from "./components/sections/Lore";
import Events from "./components/sections/Events";
import Metaverse from "./components/sections/Metaverse";
import OOTT from "./views/OOTT";

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(window.location.pathname + window.location.search);
};

const App = () => {
  const childRef = useRef();
  let location = useLocation();

  const [state, setState] = useState({
    userData: undefined,
    isOwner: false,
  });

  function setUser(newState) {
    setState({ ...state, ...newState });
  }

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add("is-loaded");
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <Route
            path="/oott/login"
            render={(props) => (
              <LoginScreen {...props} state={state} setUser={setUser} />
            )}
          />
          <PrivateRoute
            exact
            path="/oott/TigonSanctuary"
            state={state}
            component={OOTT}
          />

          <PrivateRoute
            path="/oott/TigonSanctuary/lore"
            state={state}
            component={Lore}
          />
          <PrivateRoute
            path="/oott/TigonSanctuary/events"
            state={state}
            component={Events}
          />
          <PrivateRoute
            path="/oott/TigonSanctuary/metaverse"
            state={state}
            component={Metaverse}
          />
          <PrivateRoute
            path="/oott/TigonSanctuary/shop"
            state={state}
            component={Shop}
          />
          <Redirect path="*" to={"/oott/login"} />
        </Switch>
      )}
    />
  );
};

export default App;
