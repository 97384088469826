import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Workflow ",
    paragraph: "text goes here.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <Image
                  src={
                    "https://static.wixstatic.com/media/b85c66_c8d15abb27404fd8871dbb045a988233~mv2.png"
                  }
                  alt={"TheTigonsText"}
                  className={"reveal-from-top"}
                />
                <p className={"reveal-from-top"}>
                  Each Tigon is algorithmically generated each containing
                  different accessories and attributes across a variety of
                  categories. Tigons possess incredible unique artistic features
                  as well as enhanced experiences to become functional avatars
                  within the MetaVerse virtual worlds.
                </p>
                <p className={"reveal-from-top"}>
                  Some Tigons are wild and untamed, ready to viciously attack
                  their prey and others are cute, friendly, simply looking for a
                  warm home in the MetaVerse.
                </p>
                <p className={"reveal-from-top"}>
                  A portion of the Tigon sales will be going to Hefer
                  International & will be choosing another charity by polling
                  the Tigon community after our launch.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={
                    "https://static.wixstatic.com/media/b85c66_92c2209697af48469c8cef909f10eb5e~mv2.png"
                  }
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <Image
                  src={
                    "https://static.wixstatic.com/media/b85c66_5651708a26264ce089984d1005357c9d~mv2.png"
                  }
                  alt={"OwnershipText"}
                />
                <p className={"reveal-from-top"}>
                  Ownership of one or more Tigons includes Membership into the
                  Tigon Sanctuary which provides access to a community of
                  like-minded individuals that gather online and in the
                  MetaVerse worlds to compete in virtual scavenger hunts for the
                  elusive rare Special Tigons and physical prizes.{" "}
                </p>
                <p className={"reveal-from-top"}>
                  Owning 10 or more Tigons qualifies you to enter to win some of
                  the original Jon Heder artwork created for the project. In
                  addition, all owners will have the opportunity to participate
                  in the community and vocally contribute to the project
                  roadmap.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={
                    "https://static.wixstatic.com/media/b85c66_e92396edcb794de5a28740c140aaebc8~mv2.png"
                  }
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
