import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import OottNav from "../layout/partials/oottNav";
import Image from "../elements/Image";

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
};

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
};

const Lore = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  state,
  setUser,
  ...props
}) => {
  const outerClasses = classNames(
    "lore-section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "lore-section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <>
      <OottNav navPosition="right" userData={state.userData} />
      <section {...props} className={outerClasses}>
        <div className="lore-container">
          <div className={innerClasses}>
            <h3>Observations of Tigons</h3>

            <p>
              It’s been two weeks since their arrival, and we still don’t really
              know squat about them. I think the only facts that everyone seems
              to agree on is that they call themselves “Tigons,” they came from
              outer-space, and most importantly, they are not here to kill all
              humans or steal our trees and ore.
            </p>
            <p>
              Everything else is kind of vague and shrouded in mystery. I
              suppose that’s to be expected when an alien race comes to visit.
              They definitely tick a lot of boxes when you imagine a proud and
              extremely intelligent superior race of beings: they’re large,
              strong, composed, they don’t say much, and their eyes do all the
              talking (dude, those glowing eyes…I’ll get to them later). But
              man, they certainly didn’t leave anything to the imagination when
              it came to wardrobe choice. You’d think they’d be wearing robes
              with hoods to create an aura of mystery and reverence, but these
              guys just showed up NAY-KED. We’re not talking scantily-clad…
              sure, a handful of them were wearing loin cloths and some weird
              S&M-ish body belts, but the bulk of them were buck nude and just
              embracing the breeze. Oh well, one less detail to worry about.
              Although, due to the lack of any visible dingle-dangles, its hard
              to discern genders among them, or even if gender exists in their
              race. Maybe they’ll fit in quite nicely with 21st century Earth.
            </p>
            <Image
              src={
                "https://static.wixstatic.com/media/b85c66_3035cf13f9ac4a04b6266812c5cbea01~mv2.jpeg"
              }
              alt="bi-pedal-tigon-hero"
            />
            <p>
              Obviously if they spoke English, or we spoke Tigonian(?), then
              there wouldn’t be such an information vacuum. I don’t know if they
              are unable to speak, or if they just choose not to, but it seems
              they mostly communicate through their eyes. As far as I can tell,
              when they wanna talk, their eyes glow brightly and flash all
              different colors whenever they blink. It must be some kind of
              visual morse code with different combinations of blinking and
              colors. I’m really not sure, I’m no scientist, but I tell ya, it
              looks super sick when they do it, though it’d look even sicker if
              they had some Chromeo or Daft Punk synced to it, its like watching
              an eerily silent psychedelic light show each time.
            </p>
            <p>
              So, if they’re not here to enslave humanity or drink all our
              saltwater, then what’s their purpose? Apparently, the most likely
              reason is that they wanna check us out and like, teach us their
              ways in “genetic-splicing” and “bio-hybridization” — i think it
              means they get a kick out of mixing creatures together, like,
              crossing a crocodile with an aardvark to make a ‘crocovark’…I
              don’t know, sounds gnarly and gross, and maybe a little unethical,
              but I’m not judging, i mean, what else would you expect from a
              race that is a literal hybrid of a tiger and a lion? Maybe that’s
              their way of learning more about their own species. I suppose
              they’ve built their whole civilization and culture around that
              science. And this is just a guess, but it looks like they mix
              their own kind with other intelligent alien species they come in
              contact with. Remember when they first arrived? Looking out at all
              those thousands of tigons and every now and then you’d see some
              tigon with a snake body, or werewolf head, or even a robot head.
              Maybe they go from planet to planet and if they deem you worthy,
              they grab a select few of their own and splice up with the
              resident species. Oh man, I know its probably not true, but please
              tell me there’s a planet full of werewolves out there, that’d be
              so RAD!! And now these eager beavers are here, ready to dip their
              toes in our genetic pool. I say let them have their fun, just as
              long as they don’t pick me to experiment on with a horse and
              accidentally turn me into some kind of sweaty “skin”-taur.
            </p>
            <p>
              Oh, real fast, werewolf heads were shockingly NOT the weirdest
              thing to see among them. Those cats are like hairy transformers!
              I’d say just over half of them were standing there on two legs,
              like a normal bipedal race, but then, like, the other half were
              comfortably standing on four legs. And then, out of nowhere, some
              of them would just be like, ‘nope, I wanna rock it out on four
              legs now’ and then you hear the bones cracking and cartilage
              crunching, and they would biologically transform right in front of
              your eyes. And even weirder than that, is you’d see some of the
              bipedal tigons saddled up on some of their quadrapedal comrades,
              like it was no big deal to be riding your buddy like that. And
              then they’d switch it up, so its not like it was some kind of
              social class-order nonsense. And then, EVEN weirder than that?
              Once, maybe twice, I saw a rider lean forward to the tigon he was
              sitting on and give them a kiss! Like a cowboy bending over to
              make out with his horse. I don’t know what that was all about. Not
              sure I wanna know. Just weird.
            </p>
            <p>
              Anyway, they’re here now and they’re ready to party. And as regal
              as they seem, they sure love Earthling culture and apparel. I see
              them walking around everywhere sporting everything from sunglasses
              to bolo ties to jorts. Maybe cuz they’ve never seen or understood
              the concept of clothing? Its a shocking contrast to see them
              dressed like obnoxious tourists while still quietly walking among
              us with heads high and noble gaits. A far stretch from when they
              first landed here a few weeks ago. Oh, and I literally mean
              “landed”…like on their feet, straight out of the sky. No
              spaceships, no vessels, no beaming, they just straight-up flew
              through space. I guess it’s a bit of an ordeal for them, quite the
              trek, not unlike pioneers of old traveling the Oregon trail. So
              this is how I heard it: they have like a super-powered telescope
              or something that can see across universes to help them detect
              life and organisms on distant planets. When they detect
              intelligent life, they get super excited and make preparations and
              a bunch of them get packed and ready to meet the new folk. I’m not
              sure how they decide who goes, it might just be the scientists and
              their families, but thousands of them will embark on this journey.
              They all get into this “intergalactic” catapult that’s grounded on
              their home planet, punch in the coordinates, and then it just
              launches them into space in a straight line towards their target.
              No space suits, no helmets. Just a long line of thousands of
              tigons hurtling through the stars. I don’t know how they do it. Do
              they hold their breath the whole time? Maybe that’s why they
              communicate with their eyes. Seems unlikely but who knows? It
              makes them all the more bad-ass. Oh, and they don’t use ships, but
              they do bring along like 10 or 20 of these giant space yaks that
              can hold up to like 50 or so of the younger or weaker travelers on
              their backs. And when they get to their destination, the yaks get
              docked just above the earth’s atmosphere while the tigons plop
              down feet first on the ground, like thousands of Avengers showing
              up. Then, once they’ve set up camp on the new planet, they build
              portals that can take them back to their home planet in an
              instant, or any other planet they’ve built portals on. I hope they
              let us use the portals. I’m getting giddy just thinking about that
              werewolf planet…
            </p>
            <p>
              And just like the Oregon trail, there are casualties during their
              space flight. That’s right. Despite their super-intelligence and
              technical know-how, a huge number of them still snuff it along the
              way. Who’d have thought flying through space was as dangerous as
              it is? Apparently there were just over 7,000 souls who started out
              for the pilgrimage to Earth, but only about 4,500 made it. Some
              were thrown off course by a few rogue meteorites. Others died of
              starvation. Another group of about 600 split off to take a quicker
              route, but ended up getting lost during a solar snow storm and had
              to resort to cannibalism in order to survive. Two of the space
              yaks went into heat during the months-long journey and their
              passengers were crushed when they attempted to mate with each
              other, killing about 80 or so families. That sucks. That one
              really got me. And then I think a bunch of them died from space
              dysentery and space cholera. Too bad they didn’t have space
              Pepto-Bismol. But enough about the dead, let’s celebrate the
              living! I’m sure a few of them are still floating out there, lost
              in space. Here’s to hoping that they find their way to Earth
              somehow, sometime. Welcome Tigons, welcome!
            </p>
            <p>By Jon Heder</p>
          </div>
        </div>
      </section>
    </>
  );
};

Lore.propTypes = propTypes;
Lore.defaultProps = defaultProps;

export default Lore;
