import React from "react";
// import sections
import Hero from "../components/sections/Hero";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import FeaturesSplit from "../components/sections/FeaturesSplit";
import Roadmap from "../components/sections/Roadmap";
import Attributes from "../components/sections/Attributes";
import Howtobuy from "../components/sections/Howtobuy";
import Team from "../components/sections/Team";
import About from "../components/sections/About";
import TigonArt from "../components/sections/TigonArt";
import Cta from "../components/sections/Cta";

const Home = () => {
  return (
    <>
      <Hero className="illustration-section-01" />
      <FeaturesTiles />
      <FeaturesSplit
        invertMobile
        imageFill
        className="illustration-section-02"
      />
      <Roadmap id="roadmap" className="road-map" />
      <Attributes className="attributes" />
      <Howtobuy id="howtobuy" className="how-to-buy" />
      <Team id="team" className="team" />
      <About id="about" className="about" />
      <TigonArt id="tigonart" className="tigon-art" />
      <Cta split />
    </>
  );
};

export default Home;
