import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Image from "../../elements/Image";

const Logo = ({ className, route, ...props }) => {
  const classes = classNames("brand", className);

  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
        <Link
          to=""
          onClick={() => {
            const anchor = document.querySelector(route || "#home");
            anchor.scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          <Image
            src={
              "https://static.wixstatic.com/media/b85c66_7268fad8f46b42babf2008c2657b9171~mv2.png"
            }
            alt="Open"
            width={80}
            height={80}
          />
        </Link>
      </h1>
    </div>
  );
};

export default Logo;
