import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Image from "../elements/Image";

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
};

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
};

const RoadmapSection = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className,
    "roadmap-section"
  );

  const innerClasses = classNames(
    "section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="roadmap container">
        <div className={innerClasses}>
          <div className="container-sm reveal-from-top">
            <Image
              className={"road-map-img"}
              src={
                "https://static.wixstatic.com/media/b85c66_9b9fd215a800437aa7ccecc95e5bff5e~mv2.png"
              }
              alt="roadmap"
            />
          </div>
          {/* <div className="container-sm"> */}
          <div className="phase">
            <div className="phase-containers reveal-from-top">
              <Image
                src={
                  "https://static.wixstatic.com/media/b85c66_07276ca56d9041b8aa646d7e0d5e2ab3~mv2.png"
                }
                alt="roadmap"
              />
              <ul>
                <li className="strike">Website & Social Media Launch </li>
                <li className="strike">Tigon Pre-Sale on ThetaDrop (Feb 4)</li>
                <li className="strike">
                  Live Stream with Jon Heder on Theta.tv
                </li>
              </ul>
            </div>

            <div className="phase-containers reveal-from-top">
              <Image
                src={
                  "https://static.wixstatic.com/media/b85c66_6d26c785edfc42779f84ea56b68f691d~mv2.png"
                }
                alt="roadmap"
              />
              <ul>
                <li className="strike">2nd Tigon Drop on ThetaDrop (Feb 11)</li>
                <li className="strike">Tigon Community Discord Server</li>
                <li className="strike">Airdrops to Tigon collectors</li>
              </ul>
            </div>
            <div className="phase-containers reveal-from-top">
              <Image
                src={
                  "https://static.wixstatic.com/media/b85c66_897a8c5a0f4f4cf488ca9044958057e8~mv2.png"
                }
                alt="roadmap"
              />
              <ul>
                <li className="strike">Access to the Tigon Sancturay</li>
                <li className="strike">
                  ThetaPass Exclusive Tigon Merch Store
                </li>
                <li className="strike">
                  Tigon Sandbox Game sneak peak development videos
                </li>
                <li className="strike">
                  Continual Airdrops to Tigon collectors
                </li>
              </ul>
            </div>
            <div className="phase-containers reveal-from-top">
              <Image
                src={
                  "https://static.wixstatic.com/media/b85c66_1aa71699806a4f6f9c0384c394f72785~mv2.png"
                }
                alt="roadmap"
              />
              <ul>
                <li>Mid Feb 2023 - $KFUEL Launch - Tigonia Staking & Earning on Fuel Foundry’s Staking as a Service (SaaS) Platform</li>
                <li>April / May - “Tigonia - Forever For Infinity” Experience live in The Sandbox Game</li>
                <li>Q3 2003 - Subchain Validator Staking & Metachain Participation Rewards</li>
                <li>Further Development into Tigonia Animation Pilot & Series</li>
                <li>More Tigonia Contests, Rewards, Meet the Creator (Jon Heder) and Additional Utility Unleashed</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

RoadmapSection.propTypes = propTypes;
RoadmapSection.defaultProps = defaultProps;

export default RoadmapSection;
