import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Logo from "./partials/Logo";
import HeaderSocial from "./partials/HeaderSocial";

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  navPosition: "",
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
};

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {
  const [isActive, setIsactive] = useState(false);

  const nav = useRef(null);
  const hamburger = useRef(null);

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener("keydown", keyPress);
    document.addEventListener("click", clickOutside);
    return () => {
      document.removeEventListener("keydown", keyPress);
      document.removeEventListener("click", clickOutside);
      closeMenu();
    };
  });

  const openMenu = () => {
    document.body.classList.add("off-nav-is-active");
    nav.current.style.maxHeight = nav.current.scrollHeight + "px";
    setIsactive(true);
  };

  const closeMenu = () => {
    document.body.classList.remove("off-nav-is-active");
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  };

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  };

  const clickOutside = (e) => {
    if (!nav.current) return;
    if (
      !isActive ||
      nav.current.contains(e.target) ||
      e.target === hamburger.current
    )
      return;
    closeMenu();
  };

  const classes = classNames(
    "site-header",
    bottomOuterDivider && "has-bottom-divider",
    className
  );

  return (
    <header {...props} className={classes}>
      <div className="container">
        <div
          className={classNames(
            "site-header-inner",
            bottomDivider && "has-bottom-divider"
          )}
        >
          {!hideNav && (
            <>
              <Logo />

              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={classNames("header-nav", isActive && "is-active")}
              >
                <div className="header-nav-inner">
                  <ul
                    className={classNames(
                      "list-reset text-xs",
                      navPosition && `header-nav-${navPosition}`
                    )}
                  >
                    <li>
                      <Link
                        to="#roadmap"
                        onClick={() => {
                          const roadmapAnchor =
                            document.querySelector("#roadmap");
                          roadmapAnchor.scrollIntoView({
                            behavior: "smooth",
                          });
                          closeMenu();
                        }}
                      >
                        ROAD MAP
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#howtobuy"
                        onClick={() => {
                          const roadmapAnchor =
                            document.querySelector("#howtobuy");
                          roadmapAnchor.scrollIntoView({
                            behavior: "smooth",
                          });
                          closeMenu();
                        }}
                      >
                        HOW TO BUY
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#team"
                        onClick={() => {
                          const roadmapAnchor = document.querySelector("#team");
                          roadmapAnchor.scrollIntoView({
                            behavior: "smooth",
                          });
                          closeMenu();
                        }}
                      >
                        TEAM
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#about"
                        onClick={() => {
                          const roadmapAnchor =
                            document.querySelector("#about");
                          roadmapAnchor.scrollIntoView({
                            behavior: "smooth",
                          });
                          closeMenu();
                        }}
                      >
                        ABOUT JON HEDER
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#tigonart"
                        onClick={() => {
                          const roadmapAnchor =
                            document.querySelector("#tigonart");
                          roadmapAnchor.scrollIntoView({
                            behavior: "smooth",
                          });
                          closeMenu();
                        }}
                      >
                        TIGON ART
                      </Link>
                    </li>
                    <li>
                      <Link
                        id="tigonstakinglink"
                        to={{ pathname: "https://staking.orderofthetigons.com"}}
                        target="_blank"
                        onClick={() => {
                          closeMenu();
                        }}
                      >
                        <span>TIGON STAKING</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        id="tigonsanctuarylink"
                        to="/oott/login"
                        onClick={() => {
                          closeMenu();
                        }}
                      >
                        <span>TIGON SANCTUARY</span>
                      </Link>
                    </li>
                  </ul>

                  <HeaderSocial id="social-list" />
                </div>
              </nav>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
