// import { forEach } from "lodash";
import React from "react";
// import { Link } from "react-router-dom";
import Image from "../components/elements/Image";

// import sections
import OottNav from "../components/layout/partials/oottNav";

const OOTT = (props) => {
  let { nfts, userData } = props.state;

  const listAttributes = (atts) => {
    return Object.entries(atts).map(([key, value]) => {
      return (
        <div className="atts" key={key}>
          <p className="att-key">{key}</p>
          <p className="att-value">{value}</p>
        </div>
      );
    });
  };

  return (
    <>
      <OottNav navPosition="right" userData={userData} />
      <main className="oott-site-content">
        <Image
          src={
            "https://static.wixstatic.com/media/b85c66_a7606331830f42f4af38eac660e28553~mv2.png"
          }
          alt="logo-img"
          width={160}
          height={160}
        />
        <div className="tigons-nft-container">
          {nfts &&
            nfts.types &&
            nfts.nfts.map((nft, index) => {
              return (
                <div className={"nft-container"} key={index}>
                  <Image
                    src={nft.image}
                    alt="nft-img"
                    width={290}
                    height={290}
                  />
                  <h4>{nft.name}</h4>
                  <p>
                    Attributes:{" "}
                    <span>{Object.keys(nft.attributes).length}</span>
                  </p>
                  <div className="atts-container">
                    {listAttributes(nft.attributes)}
                  </div>
                </div>
              );
            })}
        </div>
      </main>
    </>
  );
};

export default OOTT;
