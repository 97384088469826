import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Image from "../elements/Image";

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
};

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
};

const RoadmapSection = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    "tigon-art-container"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="container-xs tigon-art-title-container">
            <Image
              src={
                "https://static.wixstatic.com/media/b85c66_210bcd3c65e64deabc72ef9d3afb7544~mv2.png"
              }
              alt="about-text"
              id="about-jon-text-image"
              className="reveal-from-top"
            />
            <p className="reveal-from-top">
              Send us your Tigon pics roaming in the world or metaverse.
              <br /> We'll post them to our site.
            </p>
          </div>
          <div className="container-med tigon-art-pics-container">
            <Image
              src={
                "https://static.wixstatic.com/media/b85c66_92fd7155868c477b81716bc2ebf63be5~mv2.png"
              }
              alt="about-text"
            />
            <Image
              src={
                "https://static.wixstatic.com/media/b85c66_b1950e719afe40d1be31ab948af781fc~mv2.png"
              }
              alt="about-text"
            />
            <Image
              src={
                "https://static.wixstatic.com/media/b85c66_7fd31498c51943628a7daf5be7145e6c~mv2.png"
              }
              alt="about-text"
            />
            <Image
              src={
                "https://static.wixstatic.com/media/b85c66_08f3f455b93342b1a2c06317e2086cd0~mv2.png"
              }
              alt="about-text"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

RoadmapSection.propTypes = propTypes;
RoadmapSection.defaultProps = defaultProps;

export default RoadmapSection;
