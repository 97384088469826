import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const propTypes = {
  children: PropTypes.node,
  spin: PropTypes.bool.isRequired,
};

const defaultProps = {
  children: null,
  spin: false,
};

const Spinner = ({ className, children, spin, ...props }) => {
  useEffect(() => {
    handleBodyClass();
  }, [props.spin]);

  const handleBodyClass = () => {
    if (document.querySelectorAll(".spinner.is-loading").length) {
      document.body.classList.add("spinner-is-loading");
    } else {
      document.body.classList.remove("spinner-is-loading");
    }
  };

  const classes = classNames(
    "spinner",
    "lds-ring",
    spin && "is-loading",
    className
  );

  return (
    <>
      {spin ? (
        <div {...props} className={classes}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        children
      )}
    </>
  );
};

Spinner.propTypes = propTypes;
Spinner.defaultProps = defaultProps;

export default Spinner;
