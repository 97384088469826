import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Image from "../elements/Image";

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
};

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
};

const RoadmapSection = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    "about-container"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="container-sm">
            <Image
              src={
                "https://static.wixstatic.com/media/b85c66_170a3d6274814ed9aaf86ae574f3b82f~mv2.png"
              }
              alt="about-text"
              id="about-jon-text-image"
              className="reveal-from-top"
            />
            <Image
              src={
                "https://static.wixstatic.com/media/b85c66_a61bea3fcfd24bc69398f1546c113b78~mv2.png"
              }
              alt="about-jon-desktop-image"
              id="about-jon-desktop-image"
            />
            <Image
              src={
                "https://static.wixstatic.com/media/b85c66_dd3f9be338734846b2f4e0a7493e3585~mv2.png"
              }
              alt="about-jon-mobile-image"
              id="about-jon-mobile-image"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

RoadmapSection.propTypes = propTypes;
RoadmapSection.defaultProps = defaultProps;

export default RoadmapSection;
