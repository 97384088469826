import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import OottNav from "../layout/partials/oottNav";
import Image from "../elements/Image";

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
};

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
};

const Metaverse = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  state,
  ...props
}) => {
  const outerClasses = classNames(
    "metaverse-section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "metaverse-section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <>
      <OottNav navPosition="right" userData={state.userData} />
      <section {...props} className={outerClasses}>
        <div className="metaverse-container">
          <div className={innerClasses}>
            <Image
              src={
                "https://static.wixstatic.com/media/b85c66_8d131c3bec7b476f80fa2040580ddd62~mv2.png"
              }
              alt="bi-pedal-tigon-hero"
            />
            <h4>Coming soon to a Sandbox near you...</h4>
            <div class="container">
              <iframe
                src="https://player.thetavideoapi.com/video/video_9bqbdpe4bdxkdkxq22an5k8us8"
                class="responsive-iframe"
                // border="0"
                //   width={800}
                //   height={500}
                title="tigon-meta-teaser-1"
                allowfullscreen={true}
                mute={false}
                allow="fullscreen; autoplay"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

Metaverse.propTypes = propTypes;
Metaverse.defaultProps = defaultProps;

export default Metaverse;
