import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Image from "../elements/Image";

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
};

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
};

const RoadmapSection = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="container-xs reveal-from-top attributes">
            <Image
              src={
                "https://static.wixstatic.com/media/b85c66_2a873d1139894d1a8eba2ca0be1744f0~mv2.png"
              }
              alt="attributes-text"
            />
            <p>
              Over 150+ hand drawn attributes delivered by Jon Heder, making
              each Tigon completely unique & keeping Jon's pencil sketch style
              to life.
            </p>
            <br />
            <p>
              You will find a variety of different Tigon heads, headwear,
              backgrounds, outfits, tail-wear, accessories, & of course a ton of
              surprises!!!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

RoadmapSection.propTypes = propTypes;
RoadmapSection.defaultProps = defaultProps;

export default RoadmapSection;
