import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Image from "../elements/Image";

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
};

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
};

const RoadmapSection = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    "team"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="container-sm team-title-container">
            <Image
              src={
                "https://static.wixstatic.com/media/b85c66_4d87911c979e402fb4c1bbb56190e2cc~mv2.png"
              }
              alt="team-text"
              className={"reveal-from-top"}
            />
            <p className={"reveal-from-top"}>
              Verified Labs, LLC was founded by 3 cousins (Justin, Brian & Matt)
              that all have a passion for blockchain technology, digital, art,
              media, entertainment, and the future of the Metaverse. What
              started at the NFT idea level, ultimately expanded into launching
              the Order of the Tigons as an independent NFT project on Theta as
              well as partnering with strategic creative talent to deliver their
              digital projects to the mainstream.
            </p>
          </div>
          <Image
            src={
              "https://static.wixstatic.com/media/b85c66_023926355f134897b738f10ea431c9a2~mv2.jpg"
            }
            alt="mobile-justin-img"
            id="mobile-justin-image"
          />
          <div className="container-med team-info-container">
            <p>
              <span>CEO: Justin Trevor Winters</span>
              <br />
              <span>Occupation: </span> Founder and Producer of cool things…
              like Verified Labs and Tigons. Inspiration: As my portrait
              demonstrates, one should always place one’s fist underneath one’s
              chin to appear introspective, deep in thought, and much more
              intelligent than one truly is. My goal, and that of Verified Labs
              is to establish the truth, accuracy, and reality of hypothesized
              iterations of the internet, specifically those that support the
              creation of digital assets, 3-D avatars, and persistent virtual
              environments. Plainly speaking, we wanna make freakin’ awesome
              Tigons and sweet hybridized animals that we can own and play with
              online and in the Metaverse… so come join us and the Order of the
              Tigons.
            </p>
            <Image
              src={
                "https://static.wixstatic.com/media/b85c66_8f9bf639724b4c94bdfad61376c44778~mv2.png"
              }
              alt="justin-jon-team-img"
              className={"reveal-from-top"}
              id={"display-none"}
            />
          </div>
          <Image
            src={
              "https://static.wixstatic.com/media/b85c66_a5278683541d4c2f90c0284eec41f324~mv2.jpg"
            }
            alt="team-text"
            id="mobile-justin-jon-image"
          />
        </div>
      </div>
    </section>
  );
};

RoadmapSection.propTypes = propTypes;
RoadmapSection.defaultProps = defaultProps;

export default RoadmapSection;
