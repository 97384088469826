import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import HeaderSocial from "./HeaderSocial";
import Image from "../../elements/Image";

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  navPosition: "",
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
};

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  userData,
  ...props
}) => {
  const [isActive, setIsactive] = useState(false);

  const nav = useRef(null);
  const hamburger = useRef(null);

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener("keydown", keyPress);
    document.addEventListener("click", clickOutside);
    return () => {
      document.removeEventListener("keydown", keyPress);
      document.removeEventListener("click", clickOutside);
      closeMenu();
    };
  });

  const openMenu = () => {
    document.body.classList.add("off-nav-is-active");
    nav.current.style.maxHeight = nav.current.scrollHeight + "px";
    setIsactive(true);
  };

  const closeMenu = () => {
    document.body.classList.remove("off-nav-is-active");
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  };

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  };

  const clickOutside = (e) => {
    if (!nav.current) return;
    if (
      !isActive ||
      nav.current.contains(e.target) ||
      e.target === hamburger.current
    )
      return;
    closeMenu();
  };

  const classes = classNames(
    "oott-site-header",
    bottomOuterDivider && "has-bottom-divider",
    className
  );

  return (
    <header {...props} className={classes}>
      <div className="container">
        <div
          className={classNames(
            "oott-site-header-inner",
            bottomDivider && "has-bottom-divider"
          )}
        >
          {!hideNav && (
            <>
              <h1 className="m-0">
                <Link
                  to=""
                  onClick={() => {
                    closeMenu();
                  }}
                >
                  <Image
                    src={
                      "https://static.wixstatic.com/media/b85c66_7268fad8f46b42babf2008c2657b9171~mv2.png"
                    }
                    alt="Open"
                    width={80}
                    height={80}
                  />
                </Link>
              </h1>

              <button
                ref={hamburger}
                className="oott-header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={classNames(
                  "oott-header-nav",
                  isActive && "is-active"
                )}
              >
                <div className="oott-header-nav-inner">
                  <ul
                    className={classNames(
                      "list-reset text-xs",
                      navPosition && `oott-header-nav-${navPosition}`
                    )}
                  >
                    <li>
                      <Link
                        to="/oott/TigonSanctuary"
                        onClick={() => {
                          closeMenu();
                        }}
                      >
                        Lair
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/oott/TigonSanctuary/lore"
                        onClick={() => {
                          closeMenu();
                        }}
                      >
                        Lore
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="/oott/TigonSanctuary/events"
                        onClick={() => {
                          closeMenu();
                        }}
                      >
                        Events
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to="/oott/TigonSanctuary/metaverse"
                        onClick={() => {
                          closeMenu();
                        }}
                      >
                        Metaverse
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://tigons.thetadroprarity.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Rarity
                      </a>
                    </li>
                    <li>
                      <a
                        href={process.env.REACT_APP_LS_SK}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Store
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://tigons.thetadrop.com/"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Market
                      </a>
                    </li>
                  </ul>
                  <ul>
                    <li>@{userData.username}</li>
                    {/* <li>
                      <Image
                        src={
                          userData.avatar_url ||
                          "https://assets.thetadrop.com/0.7.341/assets/images/default_avatar.png"
                        }
                        alt="td-avatar"
                        width={100}
                        height={100}
                      />
                    </li> */}
                  </ul>
                  {/* <HeaderSocial id="oott-social-list" /> */}
                </div>
              </nav>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
