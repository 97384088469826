import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import { Link } from "react-router-dom";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    paragraph:
      "The Order of the Tigons is a collection of 4,577 unique digital art collectibles inspired by the talented creator and actor, Jon Heder. Delivered by Verified Labs, the Order of the Tigons is an independent NFT project living on the Theta blockchain.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className={innerClasses}>
        <Image
          src={
            "https://static.wixstatic.com/media/b85c66_1acd3ae0a20e4158ba0d7dedd389f5bf~mv2.png"
          }
          alt="Features tile icon 01"
          id="welcomeImg"
        />
        <div className="container-sm">
          <SectionHeader
            data={sectionHeader}
            className="center-content"
            id="welcomeDiv"
          />
        </div>

        <div className={tilesClasses}>
          <div className="tiles-item reveal-from-top">
            <div className="tiles-item-inner">
              <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16">
                  <Image
                    src={
                      "https://static.wixstatic.com/media/b85c66_2fcd28d1764847c09b0322da03602e1c~mv2.png"
                    }
                    alt="Features tile icon 01"
                    width={512}
                    height={512}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="tiles-item reveal-from-top" data-reveal-delay="200">
            <div className="tiles-item-inner">
              <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16">
                  <Image
                    src={
                      "https://static.wixstatic.com/media/b85c66_dee0e6d111ef4502b0646aeab636e745~mv2.png"
                    }
                    alt="Features tile icon 02"
                    width={512}
                    height={512}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="tiles-item reveal-from-top" data-reveal-delay="400">
            <div className="tiles-item-inner">
              <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16">
                  <Image
                    src={
                      "https://static.wixstatic.com/media/b85c66_73c5191a4b5e46b3a0e45fcdd54e53c8~mv2.png"
                    }
                    alt="Features tile icon 03"
                    width={512}
                    height={512}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
