import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import OottNav from "../layout/partials/oottNav";
import Image from "../elements/Image";

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
};

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
};

const Shop = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "shop-section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className,
    "section-how-to-buy"
  );

  const innerClasses = classNames(
    "shop-section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <>
      <OottNav navPosition="right" userData={props.state.userData} />
      <section {...props} className={outerClasses}>
        <div className="shop-container">
          <div className={innerClasses}>
            <div className="shop container-large">
              <h3>Shop coming soon!</h3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

Shop.propTypes = propTypes;
Shop.defaultProps = defaultProps;

export default Shop;
