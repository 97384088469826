import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import OottNav from "../layout/partials/oottNav";
import Image from "../elements/Image";

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
};

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
};

const Events = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  state,
  setUser,
  ...props
}) => {
  const outerClasses = classNames(
    "events-section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "events-section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <>
      <OottNav navPosition="right" userData={state.userData} />
      <section {...props} className={outerClasses}>
        <div className="lore-container">
          <div className={innerClasses}>
            <h3>Upcoming Events</h3>

            <h5>Tigon Talk - Jon, Justin, Holly, & TigonTamer</h5>
            <p>Friday, May 13th. 10:00 – 10:30am PST</p>
            <p>
              <a
                href="https://us02web.zoom.us/j/83292102580?pwd=akdKWG55N0R5aVdPWExUNTIxc0lQZz09"
                target={"_blank"}
                rel="noreferrer"
              >
                Join Zoom Meeting
              </a>
            </p>
            <p>Meeting ID: 832 9210 2580</p>
            <p>Passcode: Tigonia1</p>
            {/* <Image
              src={
                "https://static.wixstatic.com/media/b85c66_3035cf13f9ac4a04b6266812c5cbea01~mv2.jpeg"
              }
              alt="bi-pedal-tigon-hero"
            /> */}
          </div>
        </div>
      </section>
    </>
  );
};

Events.propTypes = propTypes;
Events.defaultProps = defaultProps;

export default Events;
